import { promisify } from 'es6-promisify';
// eslint-disable-next-line import/no-webpack-loader-syntax
import {
  GeoJSONSource,
  Map,
  MapLayerMouseEvent,
  Popup,
  Style,
} from '!mapbox-gl';

export interface MapService {
  initialise: () => Promise<void>;
  loadMapboxStyle: (style: Style | string) => Promise<void>;
  loadSources: (
    sources: Record<string, GeoJSON.FeatureCollection>
  ) => Promise<void>;
  setFeaturePopups: (
    popupElement: HTMLElement,
    callback: (table: string, ID: string, isOn: boolean) => void
  ) => Promise<void>;
  selectActiveLayers(layerIds: string[]): Promise<void>;
  setLayersVisibility(layerIds: string[], visible: boolean): Promise<void>;
  zoomIn(): void;
  zoomOut(): void;
  toggleRotationMode(on: boolean): void;
}

interface MapServiceOptions {
  accessToken?: string;
  centerLngLat: [number, number];
  initialZoom: number;
  container: HTMLElement | string;
}

export const createMapService = ({
  accessToken,
  centerLngLat,
  initialZoom,
  container,
}: MapServiceOptions): MapService => {
  const map = new Map({
    pitch: 10,
    accessToken,
    container,
    center: centerLngLat,
    zoom: initialZoom,
    maxBounds: [
      [-71.18598726099025, -33.76787433514144],
      [-70.17295907500919, -33.219326975777356],
    ],
  });
  (window as any).map = map;
  const loadImage = promisify(map.loadImage.bind(map));
  const images = ['icon-02.png', 'icon-05.png'];

  let isRotationModeOn = false;

  return {
    initialise: async () => {},
    async loadMapboxStyle(style) {
      map.setStyle(style);
      await Promise.all(
        images.map(async (imageUrl) => {
          const image = await loadImage(imageUrl);
          map.addImage(imageUrl, image);
        })
      );

      if (!map.isStyleLoaded()) {
        await new Promise<void>((resolve) =>
          map.once('styledata', () => {
            console.log('mapbox style loaded');
            resolve();
          })
        );
      }
    },
    async setFeaturePopups(
      popupElement: HTMLElement,
      callback: (table: string, ID: string, isOn: boolean) => void
    ) {
      // map.addSource('dem', {
      //   type: 'raster-dem',
      //   url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
      // });
      // map.addLayer({
      //   id: 'hillshading',
      //   source: 'dem',
      //   type: 'hillshade',
      //   paint: {
      //     'hillshade-shadow-color': '#336',
      //   },
      // });
      const clickHandler =
        (layerId: string) =>
        (evt: MapLayerMouseEvent): void => {
          const feature = evt.features?.[0];
          if (!feature) {
            return;
          }

          const layer = map.getLayer(layerId);
          map.setFeatureState(feature, { popupNow: true });
          if ('source' in layer && typeof layer.source === 'string') {
            callback(
              layer.source.replace('table-', ''),
              feature.properties?.ID,
              true
            );
          }

          const popup = new Popup({ closeButton: false })
            .setLngLat(evt.lngLat)
            .setDOMContent(popupElement)
            .addTo(map);
          popup.on('close', () => {
            map.setFeatureState(feature, { popupNow: false });
          });
        };
      map.getStyle().layers?.forEach((layer) => {
        if ('metadata' in layer && layer.metadata.popupOnClick) {
          map.on('click', layer.id, clickHandler(layer.id));
          map.on('mouseenter', layer.id, () => {
            map.getCanvas().style.cursor = 'pointer';
          });
          map.on('mouseleave', layer.id, () => {
            map.getCanvas().style.cursor = '';
          });
        }
      });

      // set hovers
      const layersToHover =
        map
          .getStyle()
          .layers?.filter(
            (layer) => 'metadata' in layer && layer.metadata.hover
          ) ?? [];
      layersToHover.forEach((layer) => {
        let hoveredStateId: string | number | undefined = undefined;
        let source = (layer as { source: string }).source;
        map.on('mousemove', layer.id, (evt) => {
          if (evt.features?.length) {
            if (hoveredStateId != null) {
              map.setFeatureState(
                { source, id: hoveredStateId },
                { hovering: false }
              );
            }
            hoveredStateId = evt.features[0].id;
            map.setFeatureState(
              { source, id: hoveredStateId },
              { hovering: true }
            );
          }
        });

        // When the mouse leaves the state-fill layer, update the feature state of the
        // previously hovered feature.
        map.on('mouseleave', layer.id, () => {
          if (hoveredStateId != null) {
            map.setFeatureState(
              { source, id: hoveredStateId },
              { hovering: false }
            );
          }
          hoveredStateId = undefined;
        });
      });
    },
    async loadSources(sources) {
      return Promise.all([
        new Promise<void>((resolve) => {
          map.once('load', (ev) => {
            console.log('A load event occurred.', ev);
            resolve();
          });
        }),
        ...Object.keys(sources).map(async (sourceId) => {
          (map.getSource(sourceId) as GeoJSONSource)?.setData(
            sources[sourceId]
          );
        }),
      ]).then(() => {});
    },
    async setLayersVisibility(layerIds: string[], visible: boolean) {
      map.getStyle().layers?.forEach((layer) => {
        if (layerIds.includes(layer.id)) {
          map.setLayoutProperty(
            layer.id,
            'visibility',
            visible ? 'visible' : 'none'
          );
        }
      });
    },
    async selectActiveLayers(layerIds: string[]) {
      map.getStyle().layers?.forEach((layer) => {
        if (layerIds.includes(layer.id)) {
          map.setLayoutProperty(layer.id, 'visibility', 'visible');
        } else {
          map.setLayoutProperty(layer.id, 'visibility', 'none');
        }
      });
    },
    zoomIn() {
      map.zoomIn();
    },
    zoomOut() {
      map.zoomOut();
    },
    toggleRotationMode(on) {
      if (on) {
        isRotationModeOn = true;
        map.easeTo({ pitch: 70 }, { duration: 2000 });
        map.once('pitchend', () => {
          let lastTimestamp: number = window.performance.now();
          const rotateCamera = (timestamp: number) => {
            const delta = timestamp - lastTimestamp;
            lastTimestamp = timestamp;
            map.rotateTo(map.getBearing() + ((delta / 75) % 360), {
              duration: 0,
            });
            if (isRotationModeOn) {
              requestAnimationFrame(rotateCamera);
            }
          };
          rotateCamera(lastTimestamp);
        });
      } else {
        isRotationModeOn = false;
        requestAnimationFrame(() => {
          map.easeTo({ pitch: 10, bearing: 0 }, { duration: 2000 });
        });
      }
    },
  };
};
