import { theme } from './styles';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import App from './App';

function Root(): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  );
}

export default Root;
