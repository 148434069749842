import { Box, CircularProgress } from '@material-ui/core';
import { memo } from 'react';
import { useStore } from '../stores/mainStore';

const ProgressLoading: React.FC<{}> = () => {
  const on = useStore((state) => state.progressCircleOn);
  return on ? (
    <Box position="absolute" style={{ left: '50%', top: '50%' }}>
      <Box style={{ marginTop: '-50%', marginLeft: '-50%' }}>
        <CircularProgress size={200} thickness={5} />
      </Box>
    </Box>
  ) : null;
};

export default memo(ProgressLoading);
