import { memo } from 'react';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import creditosFondoCultura from '../img/creditos-fondo-cultura.jpg';
import ContactForm from './ContactForm';

import { useStore } from '../stores/mainStore';
import { Divider, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    margin: 0,
    padding: `${theme.spacing(12)} ${theme.spacing(6)}`,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(12),
    top: theme.spacing(7),
    color: theme.palette.grey[500],
  },
}));
const { toggleInfoModal } = useStore.getState().actions;
const closeModal = () => toggleInfoModal(null);

const contactFormModal = {
  title: 'Puedes aportar al mapa mediante tu testimonio',
  content: <ContactForm />,
};

const defaultInfoModal = {
  title: 'Archivo Interactivo Cartografías de la Memoria',
  content: (
    <DialogContent dividers>
      <Typography variant="body2" paragraph>
        El Archivo Interactivo que ponemos aquí a su disposición este compuesto
        por un conjunto de documentos cartográficos construido por el colectivo{' '}
        <Link href="https://cartografiasdelamemoria.cl">
          Cartografías de la Memoria
        </Link>
        , en el marco del proyecto Fondart{' '}
        <Link href="https://cartografiasdelamemoria.cl/paginas/publicaciones">
          <strong>
            “Hacia un urbanismo de los procesos socioespaciales: Cartografías de
            la memoria. Utopía y terror en Santiago de Chile 1970-1976.”
          </strong>
        </Link>
      </Typography>
      <Typography variant="body2" paragraph>
        Entendemos la ciudad de la utopía, como aquella que se construyó sobre
        los cimientos de los ideales de las clases populares durante la UP, que
        se apropian (tanto física, como simbólicamente) de Santiago, gestando el
        denominado Poder Popular. En contraposición, entendemos como la ciudad
        del terror, aquella que encarna el violento cierre de un ciclo de auge
        de los movimientos social-populares y sus respectivas estrategias de
        posicionamiento urbano y democratización, mediante el amedrentamiento,
        presión, fuego y sangre.
      </Typography>
      <Typography variant="body2" paragraph>
        El mapa que está a punto de abrir representa momentos y situaciones de
        los años del gobierno de la UP y del Golpe de Estado. Mediante un
        trabajo de archivo y de revisión bibliográfica, hemos elaborado relatos
        cartográficos, como un ejercicio experimental de intentar fijar la
        historia en el espacio. Sin embargo, es importante hacer notar que lo
        presentado aquí está muy lejos de constituirse como una verdad, ya que
        las cartografías de la memoria son una dinámica cíclica e inacabable. Su
        finalidad no es otra más que ampliar las posibilidades de la narración
        histórica y de la relación que tenemos con nuestro pasado.
      </Typography>
      <Divider variant="middle" />
      <Typography variant="body2" paragraph>
        Las cartografías nos exhiben momentos y lugares, como si se tratara de
        fotografías de aquellos instantes donde se cristalizan las luchas y los
        conflictos, en una guerra de posiciones en la que podemos observar
        configuraciones y morfologías en el espacio urbano.
      </Typography>
      <Typography variant="body2" paragraph>
        Son momentos de excepción en la historia reciente de Santiago de Chile.
        Las unidades territoriales, espaciales y prediales de la ciudad, de
        aparente estabilidad y rigidez, de pronto son reorganizadas por acciones
        dinámicas, que modifican los límites, la distribución, la administración
        y la gestión del territorio, en un ejercicio dialéctico de
        transformación, reflejo del momento histórico que está aconteciendo. La
        ciudad es materia y representación, pero también es un espacio vivido
        que encarna la memoria colectiva de quien la habita. El espacio urbano
        se vuelve polisémico. Crisol de múltiples significados; escenario y
        protagonista de las acciones políticas; testimonio material de procesos
        conflictivos; testigo tácito del horror.
      </Typography>
      <Typography variant="body2" paragraph>
        Los mapas son una invitación a visitar las memorias de lo posible, a
        narrar el espacio y a localizar la historia. Memorias personales y
        colectivas que se pueden reunir, cotejar y visualizar en un espacio
        común y al mismo tiempo, ser una representación coreo(geo)gráfica de
        momentos convulsos de nuestra historia reciente, que sin dudas nos
        interpelan a pensar desde nuestro presente, el porvenir.
      </Typography>
      <Divider variant="middle" />
      <Typography variant="body2" paragraph>
        Visita nuestro sitio web{' '}
        <a href="https://cartografiasdelamemoria.cl">
          CartografiasDeLaMemoria.cl
        </a>{' '}
        para encontrar más información
      </Typography>
      <Typography variant="body2" paragraph>
        Integrantes del equipo
        <br />
        <ul>
          <li>Rolando Durán</li>
          <li>Danitza Valenzuela</li>
          <li>
            <Link href="https://gabrielmerida.cl" target="_blank">
              Gabriel Mérida
            </Link>
          </li>
          <li>Francisca Geisse</li>
          <li>Vicente Ibarra</li>
        </ul>
      </Typography>
      <Typography variant="body2" paragraph>
        <img
          style={{ width: '100%', height: 'auto' }}
          src={creditosFondoCultura}
          alt="Proyecto financiado por el Fondo Nacional de Desarrollo Cultural y las Artes (Fondart) 2020"
        />
      </Typography>
    </DialogContent>
  ),
};

const translatePlainContentToReact = (content: string): React.ReactNode =>
  content
    .split('\n')
    .filter(Boolean)
    .map((d) => d.trim())
    .map((d) => (
      <Typography variant="body2" paragraph>
        {d}
      </Typography>
    ));

const useInfoModal = (): { title: string; content: React.ReactNode } | void => {
  return useStore((state) => {
    const infoModal = state.infoModal;
    const modals = state.uiConfiguration?.modals ?? {};
    if (infoModal === 'cdlm') {
      return defaultInfoModal;
    }
    if (infoModal === 'contact_form') {
      return contactFormModal;
    }
    if (infoModal == null) {
      return undefined;
    }
    if (infoModal in modals) {
      const result = modals[infoModal];
      return {
        ...result,
        content: (
          <DialogContent dividers>
            {translatePlainContentToReact(result.content)}
          </DialogContent>
        ),
      };
    }
    return undefined;
  });
};
const InfoModal: React.FC<{}> = () => {
  const classes = useStyles();
  const modal = useInfoModal();
  return (
    <Dialog
      onClose={closeModal}
      aria-labelledby="customized-dialog-title"
      open={!!modal}
      fullWidth
      scroll="paper"
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography variant="h6">{modal?.title}</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={closeModal}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {modal?.content}
    </Dialog>
  );
};

export default memo(InfoModal);
