import { Fragment } from 'react';
import { theme } from './styles';
import {
  CssBaseline,
  ThemeProvider,
  Box,
  Button,
  Typography,
} from '@material-ui/core';
import ControlsCollection from './components/ControlsCollection';
import { useStore } from './stores/mainStore';
import Layout from './components/Layout';
import MapContainer from './components/MapContainer';
import { useEffect, useRef } from 'react';
import Popup from './components/Popup';
import MapFloatingButtons from './components/MapFloatingButtons';
import InfoModal from './components/InfoModal';
import ProgressLoading from './components/ProgressLoading';

const storeActions = useStore.getState().actions;

const LoadingScreen = () => <>Loading...</>;

function App(): React.ReactElement {
  const isReady = useStore((store) => store.isReady);
  const mapElement = useRef<HTMLElement>(null);
  const popupElement = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (!popupElement.current) {
      popupElement.current = document.createElement('div');
    }

    storeActions.loadAll({
      mapElement: mapElement.current!,
      popupElement: popupElement.current,
    });
  }, [mapElement]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Layout
        sidebarContent={
          <Fragment>
            <Box textAlign="center">
              <Button
                href="https://cartografiasdelamemoria.cl"
                color="primary"
                fullWidth
              >
                <Typography
                  variant="caption"
                  variantMapping={{ caption: 'div' }}
                >
                  cartografiasde
                  <br />
                  lamemoria.cl
                </Typography>
              </Button>
            </Box>
            <ControlsCollection />
          </Fragment>
        }
        mainContent={
          <>
            <MapContainer ref={mapElement} />
            <InfoModal />
            <MapFloatingButtons />
            <ProgressLoading />
          </>
        }
      />
      {popupElement.current && <Popup container={popupElement.current} />}
      {!isReady && <LoadingScreen />}
    </ThemeProvider>
  );
}

export default App;
