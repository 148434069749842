/** @jsxImportSource @emotion/core */
import React from 'react';
import { List } from '@material-ui/core';
import {
  ControlState,
  useStore,
  getControlDefinition,
} from '../stores/mainStore';
import OptionsUi from './OptionsUiControl';
import NestedOptionsSelectorFilter from './NestedOptionsSelectorFilter';

const controlStateMapper = (controlState: ControlState) => {
  const controlDefinition = getControlDefinition(controlState.id);
  switch (controlDefinition?.ui.type) {
    case 'options-ui-control': {
      const controlUiDef = controlDefinition.ui;
      return (
        <OptionsUi
          key={controlState.id}
          controlState={controlState}
          controlUiDefinition={controlUiDef}
        />
      );
    }
    case 'nested-options-ui-control': {
      const controlUiDef = controlDefinition.ui;
      return (
        <NestedOptionsSelectorFilter
          key={controlState.id}
          controlState={controlState}
          controlUiDefinition={controlUiDef}
        />
      );
    }
    default:
      return null;
  }
};

const ControlsCollection: React.FC<{}> = () => {
  const controlsState = useStore((state) => state.controlsState);

  return (
    <List disablePadding>
      {controlsState.map((controlState) => controlStateMapper(controlState))}
    </List>
  );
};

export default React.memo(ControlsCollection);
