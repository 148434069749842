import { memo, useCallback } from 'react';
import {
  Button,
  DialogContent,
  DialogActions,
  Grid,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { SelectControl, TextControl } from './formControls';
import { useStore } from '../stores/mainStore';
import { makeStyles } from '@material-ui/styles';

const valuesExistenteONueva = [...(['Existente', 'Nueva'] as const)];
const valuesCategoria = [
  ...([
    'Destrucción',
    'Incendio',
    'Barricada',
    'Saqueo',
    'Enfrentamiento',
    'Cacerolazo',
    'Expresión artística',
    'Aglomeración',
    'Uso de armas de fuego',
    'Represión',
    'Marcha',
    'Comunicado',
    'Otros',
  ] as const),
];
const valuesCategoriaConsecuencia = [
  ...([
    'Corte de flujo de transporte',
    'Dispersión de gente',
    'Desabastecimiento',
    'Cierre de servicios públicos',
    'Detenciones',
    'Heridos',
    'Torturas',
    'Muertes',
    'Otros',
  ] as const),
];

interface CdlmFormFields {
  nombre: string;
  email: string;
  telefono: string;
  existenteONueva: typeof valuesExistenteONueva[number];
  hechoOAccion?: string;
  descripcionBreve?: string;
  lugarDeLaAccion?: string;
  direccion?: string;
  tipoLugarDeLaAccion?: string;
  fecha?: Date | null;
  actores?: string;
  categoria: typeof valuesCategoria[number] | '';
  categoriaConsecuencia: typeof valuesCategoriaConsecuencia[number] | '';
  fuente?: string;
}

yup.setLocale({
  mixed: {
    required: 'Este campo es obligatorio',
  },
  string: {
    email: 'Este campo debe ser un e-mail válido',
  },
});

const schema = yup
  .object({
    nombre: yup.string().required(),
    email: yup.string().email().required(),
    telefono: yup.string().required(),
    existenteONueva: yup.string().oneOf(valuesExistenteONueva).required(),
    hechoOAccion: yup.string(),
    descripcionBreve: yup.string(),
    lugarDeLaAccion: yup.string(),
    direccion: yup.string(),
    tipoLugarDeLaAccion: yup.string(),
    fecha: yup.string(),
    actores: yup.string(),
    categoria: yup.string().oneOf([...valuesCategoria, '']),
    categoriaConsecuencia: yup
      .string()
      .oneOf([...valuesCategoriaConsecuencia, '']),
    fuente: yup.string(),
  })
  .required();

const defaultValues: CdlmFormFields = {
  nombre: '',
  email: '',
  telefono: '',
  existenteONueva: 'Nueva',
  hechoOAccion: '',
  descripcionBreve: '',
  lugarDeLaAccion: '',
  direccion: '',
  tipoLugarDeLaAccion: '',
  fecha: undefined,
  actores: '',
  categoria: '',
  categoriaConsecuencia: '',
  fuente: '',
};

const encode = (data: Record<string, any>) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

const { toggleInfoModal } = useStore.getState().actions;
const closeModal = () => toggleInfoModal(null);

const useStyles = makeStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
});

const ContactForm: React.FC<{}> = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm<CdlmFormFields>({
    resolver: yupResolver(schema, { strict: true }),
    defaultValues,
    mode: 'onTouched',
  });
  const classes = useStyles();

  const onSubmit = useCallback(async (values) => {
    console.log(values);
    if (process.env.NODE_ENV !== 'production') {
      console.log(values);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      return;
    }
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        ...values,
        'form-name': 'cdlm-archivo-interactivo-v1',
      }),
    }).catch((error) => console.error(error));
  }, []);
  if (isSubmitSuccessful) {
    return (
      <>
        <DialogContent>
          <Typography>
            Gracias por tu aporte, te contactaremos cuanto antes.
          </Typography>
        </DialogContent>
        <DialogActions>
          <div>
            <Button color="primary" onClick={() => closeModal()}>
              Cerrar
            </Button>
          </div>
        </DialogActions>
      </>
    );
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
      <DialogContent dividers>
        <Grid container spacing={10}>
          <TextControl
            {...register('nombre')}
            gridItem
            label="Nombre"
            errorText={errors.nombre?.message}
            helpText="Necesitamos tú mail o número de teléfono para que en caso de ser necesario nos comuniquemos contigo, con el objetivo de precisar la información entregada"
          />
          <TextControl
            gridItem
            {...register('email', { required: true })}
            type="email"
            label="E-mail"
            errorText={errors.email?.message}
          />
        </Grid>
        <Grid container spacing={10}>
          <TextControl
            {...register('telefono', { required: true })}
            gridItem
            label="Teléfono"
            errorText={errors.telefono?.message}
          />
        </Grid>
        <Grid container spacing={10}>
          <SelectControl
            {...register('existenteONueva')}
            gridItem
            label="Accion nueva?"
            options={['Existente', 'Nueva']}
            errorText={errors.existenteONueva?.message}
          />
          <TextControl
            {...register('hechoOAccion', { required: true })}
            gridItem
            label="Hecho o Acción"
            errorText={errors.hechoOAccion?.message}
          />
        </Grid>
        <Grid container spacing={10}>
          <TextControl
            {...register('descripcionBreve', { required: true })}
            gridItem={{ xs: 12 }}
            multiline
            label="Descripción breve"
            errorText={errors.descripcionBreve?.message}
          />
        </Grid>
        <Grid container spacing={10}>
          <TextControl
            {...register('lugarDeLaAccion', { required: true })}
            gridItem
            label="Lugar de la Acción"
            helpText="Dirección, comuna, ciudad"
            errorText={errors.lugarDeLaAccion?.message}
          />
          <TextControl
            {...register('direccion', { required: true })}
            gridItem
            label="Dirección"
            helpText="Intenta ser lo más preciso posible con la dirección, para que podamos ponerlo en el mapa (NOMBRE CALLE NUMERACIÓN). Si no sabes la dirección exacta, menciona la intersección de calles u otra referencia que nos ayude a ubicarlo en el plano"
            errorText={errors.direccion?.message}
          />
        </Grid>
        <Grid container spacing={10}>
          <TextControl
            {...register('tipoLugarDeLaAccion', { required: true })}
            gridItem
            label="Tipo de lugar"
            helpText="Indica donde ocurrió el hecho (Por ejemplo, en la calle Agustinas, el Banco del Estado, el Ministerio de Obras públicas, etc.)"
            errorText={errors.tipoLugarDeLaAccion?.message}
          />
          <TextControl
            {...register('fecha', { required: true })}
            gridItem
            type="datetime-local"
            label="Fecha"
            InputLabelProps={{
              shrink: true,
            }}
            errorText={errors.fecha?.message}
          />
        </Grid>
        <Grid container spacing={10}>
          <SelectControl
            {...register('categoria')}
            gridItem
            label="Categoría"
            options={valuesCategoria}
            errorText={errors.categoria?.message}
          />
          <SelectControl
            {...register('categoriaConsecuencia')}
            gridItem
            label="Consecuencia"
            options={valuesCategoriaConsecuencia}
            errorText={errors.categoriaConsecuencia?.message}
            helpText=" Describe si el hecho tuvo consecuencias como las que están en este listado"
          />
        </Grid>
        <Grid container spacing={10}>
          <TextControl
            {...register('actores', { required: true })}
            gridItem
            label="Actores"
            helpText="Por ejemplo, estudiantes, pobladores, militantes de algún partido político, etc."
            errorText={errors.actores?.message}
          />
          <TextControl
            {...register('fuente', { required: true })}
            gridItem
            label="Fuente"
            helpText="Carga la información o añadir enlace"
            errorText={errors.fuente?.message}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <div>
          {isSubmitting && <CircularProgress size={12} />}
          <Button type="submit" color="primary" disabled={isSubmitting}>
            Enviar
          </Button>
        </div>
      </DialogActions>
    </form>
  );
};

export default memo(ContactForm);
