import create from 'zustand';
interface State {
  refsMap: Record<string, HTMLElement>;
  setRef: (controlAndOptionId: string, el: HTMLElement | null | void) => void;
}

export const useControlPortalStore = create<State>((set) => ({
  setRef: (controlAndOptionId, el) => {
    if (el) {
      set((state) => {
        return {
          ...state,
          refsMap: {
            ...state.refsMap,
            [controlAndOptionId]: el,
          },
        };
      });
    }
  },
  refsMap: {},
}));
(window as any).useControlPortalStore = useControlPortalStore;
