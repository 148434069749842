import React, { Fragment, memo, useMemo } from 'react';
import RoomIcon from '@material-ui/icons/Room';
import { makeStyles } from '@material-ui/core';
import { Box, Divider, Portal, Typography } from '@material-ui/core';

import { useStore } from '../stores/mainStore';

interface Props {
  container: React.ComponentProps<typeof Portal>['container'];
}

const useStyles = makeStyles((theme) => ({
  title: {
    textTransform: 'uppercase',
    marginTop: 0,
  },
  divider: {
    width: '70%',
  },
  fieldName: {
    fontSize: 12,
    color: 'rgba(0,0,0,0.6)',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  fuenteSmall: {
    fontSize: 12,
    fontStyle: 'italic',
  },
  fieldValue: { marginBottom: theme.spacing(3) },
  box: {
    width: 363,
    backgroundColor: '#fff',
    margin: '-10px -10px -15px',
    padding: 20,
  },
  location: {
    fontSize: 14,
    marginLeft: -3,
    marginTop: '0.5em',
    color: 'rgba(0,0,0,0.4)',
    display: 'flex',
  },
}));

const Popup = ({ container }: Props) => {
  const classes = useStyles();
  const recordPopup = useStore((state) => state.recordPopup);
  // console.log(recordPopup);
  const jsx = useMemo(() => {
    if (!recordPopup) {
      return null;
    }
    const { table, id } = recordPopup;
    const tableDefinition =
      useStore.getState().uiConfiguration?.tableDefinitions[table];
    if (!tableDefinition?.popup) {
      return null;
    }

    const getRecordAndGeometryFromFeatureCollection = () => {
      const entry = useStore
        .getState()
        .data?.featureCollections[table]?.features.find(
          (feature) => feature.properties?.ID === id
        );
      return [entry?.properties, entry?.geometry];
    };
    const getRecordAndGeometryFromExtraTables = () => {
      return [
        useStore.getState().data?.extraTables[table]?.find((d) => d.ID === id),
        undefined,
      ];
    };
    const [record, geometry] = tableDefinition.geometry
      ? getRecordAndGeometryFromFeatureCollection()
      : getRecordAndGeometryFromExtraTables();
    if (!record) {
      return null;
    }
    const fields = tableDefinition.popup.fields.map(
      (
        popupFieldName
      ): null | { label: string; value: string | React.ReactNode } => {
        const fieldType = tableDefinition.fields[popupFieldName].type;
        switch (fieldType) {
          case 'text':
          case 'date':
            return {
              label:
                tableDefinition.fields[popupFieldName].displayShort ??
                tableDefinition.fields[popupFieldName].name,
              value: record[popupFieldName],
            };
          case 'foreignKey': {
            const field = tableDefinition.fields[popupFieldName];
            const targetTableName = field?.foreignKey?.targetTable as string;
            const targetTableDefinition =
              useStore.getState().uiConfiguration?.tableDefinitions[
                targetTableName
              ];
            const targetTable =
              useStore.getState().data?.extraTables[targetTableName];
            if (
              field == null ||
              targetTableName == null ||
              targetTable == null ||
              targetTableDefinition == null
            )
              return null;

            const targetRecords = targetTable.filter((d) =>
              record[popupFieldName]?.includes(d.ID)
            );
            if (targetRecords.length === 0) {
              return null;
            }
            const value = (
              <Fragment>
                {targetRecords.map((d) => {
                  return (
                    <Fragment>
                      <Typography variant="body1">
                        {d.Nombre as string}
                      </Typography>
                      <Typography className={classes.fuenteSmall}>
                        {d.APA as string}
                      </Typography>
                    </Fragment>
                  );
                })}
              </Fragment>
            );
            return {
              label: field.displayShort ?? field.name,
              value,
            };
          }
          default:
            return null;
        }
      }
    );

    return (
      !!fields.length && (
        <Box className={classes.box}>
          <Typography className={classes.fieldName}>
            {tableDefinition.popup.featureType}
          </Typography>
          {fields.map((fieldItem, idx) => {
            if (fieldItem == null) {
              return null;
            }
            const { label, value } = fieldItem;
            if (idx === 0) {
              return (
                <h3 key={idx} className={classes.title}>
                  {value}
                </h3>
              );
            }
            return (
              <Fragment>
                <Typography className={classes.fieldName}>{label}</Typography>
                {typeof value === 'string' || typeof value === 'number' ? (
                  <Typography className={classes.fieldValue}>
                    {value}
                  </Typography>
                ) : (
                  value
                )}
                {idx < fields.length - 1 && (
                  <Divider className={classes.divider} />
                )}
              </Fragment>
            );
          })}
          {geometry && tableDefinition.geometry?.type === 'Point' && (
            <Fragment>
              <Divider />
              <Typography className={classes.location}>
                <RoomIcon fontSize="small" />
                <Box display="inline" ml={2}>
                  {geometry.coordinates[1]?.toFixed?.(6)},{' '}
                  {geometry.coordinates[0]?.toFixed?.(6)}
                </Box>
              </Typography>
            </Fragment>
          )}
        </Box>
      )
    );
  }, [recordPopup, classes]);

  return <Portal container={container}>{jsx}</Portal>;
};

export default memo(Popup);
