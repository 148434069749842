import React, { Fragment, memo, forwardRef, useMemo } from 'react';
import HelpIcon from '@material-ui/icons/Help';
import { uniqueId } from 'lodash';
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  GridProps,
  InputAdornment,
  InputLabel,
  Select,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { UseFormRegisterReturn } from 'react-hook-form';
import { withStyles } from '@material-ui/core/styles';

const BigTooltip = withStyles((_theme) => ({
  tooltip: {
    fontSize: 13,
  },
}))(Tooltip);

interface ControlProps {
  gridItem?: true | GridProps;
  label: string;
  errorText?: string;
  helpText?: string;
}

type TextControlProps = ControlProps &
  React.ComponentProps<typeof TextField> &
  UseFormRegisterReturn & {};

const usePotentialGridWrapper = (gridItem?: true | GridProps) => {
  return useMemo<[typeof Grid, GridProps] | [typeof Fragment, {}]>(() => {
    if (gridItem === true) {
      return [Grid, { item: true, xs: 12, sm: 6 }];
    } else if (gridItem) {
      return [Grid, { item: true, ...gridItem }];
    }
    return [Fragment, {}];
  }, [gridItem]);
};

const TextControl = memo(
  forwardRef(
    (
      { errorText, gridItem, helpText, ...props }: TextControlProps,
      ref: React.ForwardedRef<HTMLDivElement | null>
    ) => {
      const [Wrapper, wrapperProps] = usePotentialGridWrapper(gridItem);
      return (
        <Wrapper {...wrapperProps}>
          <TextField
            {...props}
            fullWidth
            minRows={props.multiline ? 2 : undefined}
            error={!!errorText}
            helperText={errorText}
            ref={ref}
            InputProps={{
              endAdornment: helpText && (
                <InputAdornment position="end">
                  <BigTooltip title={helpText} placement="top" arrow>
                    <HelpIcon color="primary" fontSize="small" />
                  </BigTooltip>
                </InputAdornment>
              ),
            }}
          />
        </Wrapper>
      );
    }
  )
);

interface SelectControlProps extends ControlProps, UseFormRegisterReturn {
  options: { value: any; label: string }[] | string[];
}

const SelectControl = memo(
  forwardRef(
    (
      {
        label,
        errorText,
        options,
        gridItem,
        helpText,
        ...registerFields
      }: SelectControlProps,
      ref: React.ForwardedRef<HTMLDivElement | null>
    ) => {
      const labelId = useMemo(() => uniqueId('select-control'), []);
      const [Wrapper, wrapperProps] = usePotentialGridWrapper(gridItem);
      return (
        <Wrapper {...wrapperProps}>
          <Box flexGrow={1} display="flex">
            <FormControl fullWidth>
              <InputLabel id={labelId}>{label}</InputLabel>
              <Select
                {...registerFields}
                native
                labelId={labelId}
                id="demo-simple-select"
                ref={ref}
              >
                {options.map((option) => {
                  const [label, value] =
                    typeof option === 'string'
                      ? [option, option]
                      : [option.label, option.value];
                  return (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  );
                })}
              </Select>
              {errorText && <FormHelperText error>{errorText}</FormHelperText>}
            </FormControl>
            {helpText && (
              <Box flexGrow={0} flexShrink={0} alignSelf="center">
                <BigTooltip title={helpText} placement="top" arrow>
                  <HelpIcon color="primary" fontSize="small" />
                </BigTooltip>
              </Box>
            )}
          </Box>
        </Wrapper>
      );
    }
  )
);

export { TextControl, SelectControl };
