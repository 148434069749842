import { createTheme } from '@material-ui/core/styles';

const spacing = 2;

export const theme = createTheme({
  shape: {
    borderRadius: 0,
  },
  spacing,
  overrides: {
    MuiButton: {
      root: {
        'min-width': 'auto',
      },
    },
    MuiDivider: {
      middle: {
        marginTop: spacing * 4,
        marginBottom: spacing * 4,
      },
    },
  },
  props: {
    MuiButton: {
      size: 'small',
    },
    MuiFilledInput: {
      margin: 'dense',
    },
    MuiFormControl: {
      margin: 'dense',
    },
    MuiFormHelperText: {
      margin: 'dense',
    },
    MuiIconButton: {
      size: 'small',
    },
    MuiInputBase: {
      margin: 'dense',
    },
    MuiInputLabel: {
      margin: 'dense',
    },
    MuiListItem: {
      dense: true,
    },
    MuiOutlinedInput: {
      margin: 'dense',
    },
    MuiFab: {
      size: 'small',
    },
    MuiTable: {
      size: 'small',
    },
    MuiTextField: {
      margin: 'dense',
    },
    MuiToolbar: {
      variant: 'dense',
    },
  },
});
