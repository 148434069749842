import create from 'zustand';
import {
  FullMapDataSet,
  isValidUiConfiguration,
  UiConfiguration,
  Control,
} from '@cdlm-ai/mapa-dominio';
import { createMapService, MapService } from '../mapService';

import { mapKeys, mapValues, memoize } from 'lodash';
// @TODO: import from mapa-dominio
export interface Dataset {}

export interface ControlState {
  id: string;
  on: boolean;
  value: any;
}

function buildGeojsonSources(
  data: FullMapDataSet
): Record<string, GeoJSON.FeatureCollection> {
  return mapKeys(
    mapValues(
      data.featureCollections,
      (featureCollection): GeoJSON.FeatureCollection => featureCollection
    ),
    (_, tableName) => `table-${tableName}`
  );
}

interface Actions {
  toggleInfoModal: (modal: string | null) => void;
  loadAll: (options: {
    mapElement: HTMLElement;
    popupElement: HTMLElement;
  }) => Promise<void>;
  loadDataIntoMap: () => Promise<void>;
  makeControlChange: (
    filterId: string,
    value: unknown,
    initialPass?: boolean
  ) => Promise<void>;
  selectRecordPopup: (popup: { table: string; id: string } | false) => void;
  toggleRotationMode(on?: boolean): void;
}

interface State {
  uiConfiguration?: UiConfiguration;
  mapService?: MapService;
  isReady: boolean;
  data?: FullMapDataSet;
  mapIsLoaded: boolean;
  controlsState: ControlState[];
  recordPopup?: { table: string; id: string };
  infoModal: string | null;
  mapRotationModeOn: boolean;
  progressCircleOn: boolean;
}

export const useStore = create<State & { actions: Actions }>((set, get) => ({
  actions: {
    toggleInfoModal: (modal: string | null) => set({ infoModal: modal }),
    loadAll: async ({ mapElement, popupElement }) => {
      // @ts-ignore
      window.mainStore = useStore;

      const mapService = createMapService({
        accessToken:
          'pk.eyJ1IjoiZ21lcmlkYTI2NjYiLCJhIjoiY2tvNzdybmk5MGlvYTJ2cHdpdThhbnB3biJ9.2GnqDrl2-qCfkgRAHh_WXg',
        container: mapElement,
        centerLngLat: [-70.6347012, -33.4373124],
        initialZoom: 11,
      });
      (window as any).mapService = mapService;
      set({ mapService });
      await mapService.initialise();
      const response = await fetch('./fullMapDataset.json');
      const { data, uiConfiguration } = await response.json();
      console.log({ data, uiConfiguration });
      if (isValidUiConfiguration(uiConfiguration)) {
        set({ uiConfiguration, data });
      }
      await get().actions.loadDataIntoMap();

      get().uiConfiguration?.controls.forEach((control) => {
        if (control.ui.options.setOnStart) {
          get().actions.makeControlChange(
            control.id,
            control.ui.options.initialValue,
            true
          );
        }
      });
      await mapService.setFeaturePopups(
        popupElement,
        (table: string, id: string, isOn: boolean) => {
          get().actions.selectRecordPopup(isOn && { table, id });
        }
      );
      set({ isReady: true });
    },
    loadDataIntoMap: async () => {
      console.log('loadDataIntoMap', get());
      await Promise.resolve();
      const { data, uiConfiguration, mapService } = get() as Required<State>;
      set({
        controlsState: uiConfiguration.controls.map((control) => ({
          id: control.id,
          on: false,
          value: control.ui.options.initialValue,
          // controlDef: control,
        })),
      });
      if (uiConfiguration.mapboxStyle) {
        await mapService.loadMapboxStyle(uiConfiguration.mapboxStyle);
        await mapService.loadSources(buildGeojsonSources(data));
        set({ progressCircleOn: false });
      }
    },
    makeControlChange: async (controlId, value, initialPass = false) => {
      get()
        .uiConfiguration?.controls.filter(
          (control) =>
            !initialPass &&
            (control.ui.options?.resetOnControlChange ?? []).includes(controlId)
        )
        ?.forEach((control) => {
          get().actions.makeControlChange(
            control.id,
            control.ui.options.initialValue,
            true
          );
        });

      const controlEffect = get().uiConfiguration?.controls.find(
        ({ id }) => controlId === id
      )?.effect;
      if (!controlEffect) return;
      // @TODO: use interfaces for FilterResults
      if (controlEffect.type === 'mapbox-select-layers') {
        set(({ controlsState }) => ({
          controlsState: controlsState.map((controlState) =>
            controlState.id === controlId
              ? { ...controlState, value }
              : controlState
          ),
        }));
        const layerIds = (value as string[]).reduce(
          (acc: string[], singleValue) => [
            ...acc,
            ...controlEffect.mapValueToAllowListLayer[singleValue],
          ],
          []
        );
        const layerIdsToHide: string[] = Object.keys(
          controlEffect.mapValueToAllowListLayer ?? {}
        ).reduce((acc: string[], valueEntry: string): string[] => {
          return (value as string[]).includes(valueEntry)
            ? acc
            : [
                ...acc,
                ...(controlEffect.mapValueToAllowListLayer[valueEntry] ?? []),
              ];
        }, []);
        if (controlEffect.options?.skipHidingOtherLayers) {
          get().mapService?.setLayersVisibility(layerIdsToHide, false);
          return get().mapService?.setLayersVisibility(layerIds, true);
        } else {
          return get().mapService?.selectActiveLayers(layerIds);
        }
      }
    },
    selectRecordPopup: (popup) => {
      set({ recordPopup: popup || undefined });
    },
    toggleRotationMode: (on) => {
      const current = get().mapRotationModeOn;
      if (on == null) {
        on = !current;
      }
      if (current !== on) {
        set({ mapRotationModeOn: on });
        get().mapService?.toggleRotationMode(on);
      }
    },
  },
  isReady: false,
  controlsState: [],
  mapIsLoaded: false,
  infoModal: null,
  mapRotationModeOn: false,
  progressCircleOn: true,
}));

const { getState } = useStore;
export { getState };

export const getControlDefinition = memoize(
  (controlId: string): Control | void =>
    useStore
      .getState()
      .uiConfiguration!.controls.find(({ id }) => id === controlId)
);
