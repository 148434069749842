import AddCommentIcon from '@material-ui/icons/AddComment';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { memo } from 'react';
import classnames from 'classnames';
import { useStore } from '../stores/mainStore';

const useStyles = makeStyles({
  rootBox: {
    position: 'fixed',
    bottom: 60,
    right: 60,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: -20,
  },
  button: {
    backgroundColor: '#fff',
    border: '1px solid #000',
    width: 50,
    height: 50,
    marginBottom: 20,
    '&:hover': {
      backgroundColor: '#aaa', // @TODO: Change to purple?
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
  buttonToggled: {
    backgroundColor: '#ccc',
  },
});

const { toggleInfoModal, toggleRotationMode } = useStore.getState().actions;
const openInfoModal = () => toggleInfoModal('cdlm');
const openFormModal = () => toggleInfoModal('contact_form');

const MapFloatingButtons = () => {
  const classes = useStyles();
  const mapService = useStore((state) => state.mapService);
  const mapRotationModeOn = useStore((state) => state.mapRotationModeOn);
  return (
    <>
      {mapService && (
        <Box className={classes.rootBox}>
          <Button
            variant="outlined"
            className={classes.button}
            onClick={openInfoModal}
          >
            <InfoOutlinedIcon />
          </Button>
          <Button
            variant="outlined"
            className={classnames(
              classes.button,
              mapRotationModeOn && classes.buttonToggled
            )}
            onClick={() => toggleRotationMode()}
          >
            <RotateLeftIcon />
          </Button>
          <Button
            variant="outlined"
            disableElevation
            className={classes.button}
            onClick={() => mapService.zoomIn()}
          >
            <ZoomInIcon />
          </Button>
          <Button
            variant="outlined"
            className={classes.button}
            onClick={() => mapService.zoomOut()}
          >
            <ZoomOutIcon />
          </Button>
          <Button
            variant="outlined"
            className={classes.button}
            onClick={openFormModal}
          >
            <AddCommentIcon />
          </Button>
        </Box>
      )}
    </>
  );
};

export default memo(MapFloatingButtons);
