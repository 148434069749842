import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import React, { memo, useEffect, useState } from 'react';
import classnames from 'classnames';
interface Props {
  sidebarContent: React.ReactNode;
  mainContent: React.ReactNode;
  onLoad?: () => void;
}

const boxWidth = 112;
const boxExpandedWidth = 168;
const boxHeight = 104;

const useStyles = makeStyles({
  floatingBox: {
    transition: 'height 0.3s ease-in-out, width 0.3s ease-in-out',
  },
  h1: { textTransform: 'none' },
});
const Layout = ({ sidebarContent, mainContent, onLoad }: Props) => {
  const classes = useStyles();
  useEffect(() => {
    onLoad?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [isExpanded, setExpanded] = useState(false);

  return (
    <Box height="100%" display="flex">
      <Box
        border={1}
        borderColor="rgba(0, 0, 0, 0.6)"
        width={isExpanded ? boxExpandedWidth : boxWidth}
        height={isExpanded ? 'calc(100vh - 70px)' : boxHeight}
        zIndex={3}
        position="fixed"
        top={35}
        left={35}
        display={'flex'}
        flexDirection={'column'}
        overflow={'hidden'}
        bgcolor="background.paper"
        p={0}
        className={classnames(classes.floatingBox)}
      >
        <Box clone height={boxHeight} flexShrink={0}>
          <Button onClick={() => setExpanded((state) => !state)} disableRipple>
            <h1 className={classes.h1}>CdlM</h1>
            {!isExpanded && <ArrowDownwardIcon />}
          </Button>
        </Box>

        <Box height="100%" width={boxExpandedWidth} overflow="hidden scroll">
          {sidebarContent}
        </Box>
      </Box>
      <Box component="main" flexGrow={1}>
        {mainContent}
      </Box>
    </Box>
  );
};

export default memo(Layout);
