import { memo } from 'react';
import { ControlUi } from '@cdlm-ai/mapa-dominio';

export interface NestedOptionsSelectorFilterProps {
  controlUiDefinition: Extract<
    ControlUi,
    { type: 'nested-options-ui-control' }
  >;
  controlState: {};
}

const NestedOptionsSelectorFilter: React.FC<NestedOptionsSelectorFilterProps> =
  (props) => {
    return <div />;
  };

export default memo(NestedOptionsSelectorFilter);
