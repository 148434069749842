import { Fragment, useState, useMemo, useCallback } from 'react';
import { uniq } from 'lodash';
import { makeStyles } from '@material-ui/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  Box,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Portal,
  IconButton,
} from '@material-ui/core';
import { memo } from 'react';
import { ControlUi } from '@cdlm-ai/mapa-dominio';
import { ControlState, useStore } from '../stores/mainStore';
import { useControlPortalStore } from '../stores/controlPortalStore';

const { toggleInfoModal } = useStore.getState().actions;
const { setRef } = useControlPortalStore.getState();

function getWrapper(controlPortal?: string): React.FC<{}> {
  if (controlPortal) {
    return ({ children }) => {
      const container = useControlPortalStore(
        (state) => state.refsMap[controlPortal]
      );
      return <Portal container={container}>{children}</Portal>;
    };
  }
  return ({ children }) => <>{children}</>;
}
function switchInList<T>(list: T[], value: T): T[] {
  return list.includes(value)
    ? list.filter((d) => d !== value)
    : [...list, value];
}

interface OptionsUiControlState extends ControlState {
  value: string[];
}
export interface OptionsUiControlProps {
  controlUiDefinition: Extract<ControlUi, { type: 'options-ui-control' }>;
  controlState: OptionsUiControlState;
}

const useStyles = makeStyles({
  arrowIcon: {
    color: 'rgba(0,0,0,0.54)',
  },
  checkboxListItem: {
    boxShadow: 'inset 0px -1px 0px #E0E0E0',
  },
  checkboxListItemIcon: {
    minWidth: 'auto',
  },
  checkboxViewCheckbox: {
    paddingLeft: 5,
    paddingTop: 0,
    paddingBottom: 0,
  },
});
const actions = useStore.getState().actions;

const getData = (
  dataSelector: Extract<
    ControlUi,
    { type: 'options-ui-control' }
  >['dataSelector']
): Array<{ label: string; value: string }> => {
  switch (dataSelector.type) {
    case 'from-table-data-selector': {
      const { sourceTable, allowList, nameField } = dataSelector.options;
      const mapsTableDefinition =
        useStore.getState().uiConfiguration?.tableDefinitions[sourceTable];
      const { valueField } = dataSelector.options;
      const result: Array<{ label: string; value: string }> | void =
        mapsTableDefinition?.geometry
          ? useStore
              .getState()
              .data?.featureCollections[sourceTable].features.map(
                (feature) => ({
                  label: feature.properties?.[nameField]!,
                  value: feature.properties?.[valueField]!,
                })
              )
          : useStore
              .getState()
              .data?.extraTables[sourceTable].map((record) => ({
                label: record[nameField],
                value: record[valueField],
              }));
      const filteredResult = allowList
        ? (result ?? []).filter((d) => allowList.includes(d.value))
        : result ?? [];
      return filteredResult;
    }
    case 'from-table-enum-data-selector': {
      const { sourceTable, allowList, nameField } = dataSelector.options;
      const mapsTableDefinition =
        useStore.getState().uiConfiguration?.tableDefinitions[sourceTable];
      const enumItems: string[] | void = mapsTableDefinition?.geometry
        ? useStore
            .getState()
            .data?.featureCollections[sourceTable].features.map(
              (feature) => feature.properties?.[nameField]!
            )
        : useStore
            .getState()
            .data?.extraTables[sourceTable].map((record) => record[nameField]);
      const flattenedResult: string[] = uniq(enumItems ?? []).filter(
        allowList ? (d) => allowList.includes(d) : Boolean
      );
      return flattenedResult.map((entry) => ({ label: entry, value: entry }));
    }
    case 'arbitrary-data-selector':
      return dataSelector.options.optionsList;
    default:
      return [];
  }
};

const ContainerSecondaryAction: React.FC<{
  value: string[];
  entry: { value: string; label: string; color?: string; modal?: string };
}> = ({ value, entry }) => {
  const classes = useStyles();
  if (value.includes(entry.value)) {
    if (entry.modal != null) {
      const modal = entry.modal;
      return (
        <ListItemSecondaryAction onClick={() => toggleInfoModal(modal)}>
          <IconButton>
            <InfoOutlinedIcon />
          </IconButton>
        </ListItemSecondaryAction>
      );
    } else {
      return <ArrowUpwardIcon className={classes.arrowIcon} />;
    }
  }

  return <ArrowDownwardIcon className={classes.arrowIcon} />;
};

interface OptionsUiViewProps {
  controlId: string;
  entries: Array<{ value: string; label: string; color?: string }>;
  controlUiDefinition: Extract<ControlUi, { type: 'options-ui-control' }>;
  value: string[];
  onSelect: (value: string) => void;
}
const OptionsUiContainerView: React.FC<OptionsUiViewProps> = ({
  controlId,
  entries,
  value,
  onSelect,
}) => {
  return (
    <ListItem disableGutters>
      <List>
        {entries.map((entry) => (
          <Fragment key={entry.value}>
            <ListItem button onClick={() => onSelect(entry.value)}>
              <ListItemText
                disableTypography
                primary={<Box fontWeight="fontWeightMedium">{entry.label}</Box>}
              />
              <ContainerSecondaryAction value={value} entry={entry} />
            </ListItem>
            <Divider />
            <ListItem
              disableGutters
              dense
              style={{
                display: value.includes(entry.value) ? 'block' : 'none',
              }}
            >
              <div
                ref={(el: HTMLDivElement) => {
                  setRef(`${controlId}-${entry.value}`, el);
                }}
              />
            </ListItem>
          </Fragment>
        ))}
      </List>
    </ListItem>
  );
};
const OptionsUiCheckboxView: React.FC<OptionsUiViewProps> = ({
  entries,
  controlUiDefinition,
  value,
  onSelect,
}) => {
  const classes = useStyles();
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  return (
    <>
      {!controlUiDefinition.options.hideTitle && (
        <Fragment>
          <ListItem button onClick={() => setIsMenuOpen((state) => !state)}>
            <ListItemText primary={controlUiDefinition.title} />
            <ArrowDownwardIcon className={classes.arrowIcon} />
          </ListItem>
          <Divider />
        </Fragment>
      )}
      <div style={{ display: isMenuOpen ? 'block' : 'none' }}>
        {entries.map((entry) => (
          <Fragment key={entry.value}>
            <ListItem
              dense
              button
              onClick={() => onSelect(entry.value)}
              className={classes.checkboxListItem}
            >
              <ListItemIcon className={classes.checkboxListItemIcon}>
                <Checkbox
                  className={classes.checkboxViewCheckbox}
                  edge="start"
                  size="small"
                  style={{ color: entry.color ?? '#333' }}
                  checked={value.includes(entry.value)}
                  tabIndex={-1}
                  disableRipple
                  color="primary"
                  // checkedIcon={
                  //   <IndustryIcon fontSize="small" color="primary" />
                  // }
                  // icon={<IndustryIcon fontSize="small" color="disabled" />}
                  inputProps={{ 'aria-labelledby': entry.label }}
                />
              </ListItemIcon>
              <ListItemText id={`${entry.label}`} primary={entry.label} />
            </ListItem>
          </Fragment>
        ))}
      </div>
    </>
  );
};

const OptionsUiControl: React.FC<OptionsUiControlProps> = (props) => {
  const { id, value } = props.controlState;
  console.log('render OptionsUi', id);
  const dataSelector = props.controlUiDefinition.dataSelector;
  const allowMultiple =
    props.controlUiDefinition.options?.allowMultiple ?? false;
  const entries: Array<{ label: string; value: string; color?: string }> =
    useMemo(() => getData(dataSelector), [dataSelector]);

  const handleClick = useCallback(
    (optionValue: string) => {
      const newValue = allowMultiple
        ? switchInList(value, optionValue)
        : [optionValue];
      actions.makeControlChange(id, newValue);
    },
    [id, allowMultiple, value]
  );

  // const Wrapper = useMemo(
  //   () => getWrapper(),
  //   [props.controlUiDefinition.options.parentControlAndValue]
  // );
  const Wrapper = useMemo(
    () => getWrapper(props.controlUiDefinition.options.parentControlAndValue),
    [props.controlUiDefinition.options.parentControlAndValue]
  );

  if (!entries?.length) {
    return null;
  }
  const Component = props.controlUiDefinition.options.containerStyle
    ? OptionsUiContainerView
    : OptionsUiCheckboxView;
  return (
    <Wrapper>
      <Component
        controlId={id}
        entries={entries}
        controlUiDefinition={props.controlUiDefinition}
        onSelect={handleClick}
        value={value}
      />
    </Wrapper>
  );
};

export default memo(OptionsUiControl);
